import React from "react";
import Button from "../custom-widgets/button";

const NavLinksSection = ({
  id = "nav-links",
  links = [],
  containerClass = "",
  numberOfColumns = 2,
  noPaddingVertical = false
}) => {
  const { length } = links;
  const elementsPerColumn = Math.ceil(length / numberOfColumns);

  const columns = Array.from({ length: numberOfColumns }, (_, i) => {
    const startIndex = i * elementsPerColumn;
    const endIndex = Math.min(startIndex + elementsPerColumn, length);
    const columnElements = links.slice(startIndex, endIndex);

    return (
      <div key={i} className={`col-md-${12 / numberOfColumns}`}>
        {columnElements.map((props, index) => {
          const isLastElementInColumn = index === columnElements.length - 1;
          const isLastElementInLinks = props.id === links[length - 1].id;
          const containerClass = isLastElementInColumn ? (isLastElementInLinks ? "mb-0" : "mb-3 mb-md-0") : "mb-3";

          return (
            <Button
              key={props.id}
              id={`nav-btn-${props.id}`}
              type="link"
              url={`#${props.id}`}
              text={props.text}
              class="btn-anchor-link d-flex no-min-width px-0 text-left"
              containerClass={containerClass}
              icon={{
                position: "left",
                lib: "fal",
                name: "arrow-down",
                class: "mt-1 mr-2"
              }}
            />
          );
        })}
      </div>
    );
  });

  return (
    <section id={id} className={"row " + containerClass + " " + (noPaddingVertical && "py-0")}>
      {columns}
    </section>
  );
};

export default NavLinksSection;
